.skills-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 75%;
  img {
    width: 10vw;
    max-width: 100px;
    margin: 2rem;
    opacity: 0;
    animation: unfocus 3s ease 1 forwards;
  }
  img:nth-of-type(1) {animation-delay: 0s;}
  img:nth-of-type(2) {animation-delay: 0.2s;}
  img:nth-of-type(3) {animation-delay: 0.4s;}
  img:nth-of-type(4) {animation-delay: 0.6s;}
  img:nth-of-type(5) {animation-delay: 0.8s;}
  img:nth-of-type(6) {animation-delay: 1s;}
  img:nth-of-type(7) {animation-delay: 1.2s;}
  img:nth-of-type(8) {animation-delay: 1.4s;}
}

@keyframes unfocus {
  0% {filter: blur(5px); transform: perspective(200px) translateZ(-100px); opacity: 0;}
  100% {filter: blur(0); transform: perspective(200px) translateZ(0); opacity: 1;}
}
