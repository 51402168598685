.projects {
  width: 80%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.project-text {
  min-width: 250px !important;
  max-width: 50%;
}

.project-section {
  height: 50vh;
  padding-bottom: 10vh;
  overflow: scroll;
}

.project-section:after {
  content  : "";
  position : absolute;
  z-index  : 1;
  bottom   : 0;
  left     : 0;
  pointer-events   : none;
  background : linear-gradient(to bottom, 
                    rgba(237, 85, 59, 0), 
                    rgba(237, 85, 59, 1) 90%);
    
  width    : 100%;
  height   : 4em;
}

.frame-image {
  border-radius: 5px;
  max-width: 50%;
  margin-right: 20px;
  max-height: 400px;
  img {
    margin: 0 auto 20px auto;
  }
}

@media only screen and (max-width: 600px) {
  .frame-image {
    max-height: 250px;
  }
}

/* The browser window */
.browser-container {
  border: .1vw solid #f1f1f1;
  background: #f1f1f1;
  border-radius: .5vw;
  overflow: hidden;
  min-width: 250px !important;
  width: 50% !important;
  margin-bottom: 2.5vw;
  margin-right: 2.5vw;
}

/* Container for columns and the top "toolbar" */
.browser-row {
  padding: .5vh 0 .5vh 0;
  width: 100%;
}

/* Clear floats after the columns */
.browser-row:after {
  content: "";
  display: table;
  clear: both;
}

/* Three dots */
.browser-dot {
  margin: .4vw;
  height: 2.2vh;
  max-height: 12px;
  width: 2.2vh;
  max-width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

/* Style the input field */
.search-box {
  border-radius: 5px;
  border: none;
  background-color: white;
  font-size: 1vh;
  width: 20vw;
  color: #666;
  padding: .5vh;
  overflow: hidden;
}

/* Three bars (hamburger menu) */
.browser-bar {
  width: .8vw;
  height: .2vw;
  background-color: #aaa;
  margin: .3vw 0;
  display: block;
}

/* Page content */
.browser-content {
  padding: 0;
}

.browser-dot1 {
  background:#ED594A;
}

.browser-dot2 {
  background:#FDD800;
}

.browser-dot3 {
  background:#5AC05A;
}

.techbadge {
  fill: white; 
  height: 5vw;
  max-height: 30px;
  margin: .5vw;
}

.project-links {
  background-color: #24305E;
  width: 8vw;
  min-width: 50px;
  height: 4vh;
  border-radius: 5px;
  text-align: center;
  a {
    width: 100%;
    line-height: 4vh;
    font-size: calc(7px + .5vw);
    color: white;
  }
}
