@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

html, body { min-height: 100%; background-color: #ED553B; }

.App {
  text-align: center;
  background-color: #ED553B;
  width: 100vw;
  height: 100vh;
  color: white;
  font-family: 'Oswald', sans-serif;
}

@media (max-width: 991px) {
  .navbar-collapse {
      position: fixed;
      top: 0;
      left: 0;
      width: 75%;
      height: 100%;
      transform: translateX(-100%);
      background-color:#7e1806;
      padding: 0 !important;
      margin: 0 !important;
  }

  .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
    max-height: none;
  }

  .collapse {
    transform: translateX(0%);
    transition: transform 300ms linear !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .collapsing {
    transform: translateX(-100%);
    transition: transform 300ms linear !important;
  }

  .navbar-nav > a {
    color: white !important;
    font-size: 30px !important;
    margin: 10px; 
  }

}



.navbar {
  z-index: 10000;
}

.navbar > button {
  border-color: white !important;
  color: white !important;
}

.navbar-nav > a {
  color: white !important;
  font-size: calc(7px + .5vw);
}

.navbar-nav > .active {
  color: #ED553B !important;
  background-color: white;
  border-radius: .5vw;
}

.navbar-toggler-icon {
  color: white !important;
}

.logo-block {
  position: absolute;
  width: 80vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3vw;
}

.logo {
  opacity: 0;
  animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.slogan-words {
  margin: 1vw;
  font-size: 2vw;
  letter-spacing: .8vw;
  opacity: 0;
  font-weight: 400;
  text-align: center;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
  animation-duration: 1s;
  animation-delay: 3.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeInOpacity {
	0% {
    opacity: 0;
	}
	100% {
    opacity: 1;
	}
}

.toggle-logo-enter {
  position: absolute;
  width: 80vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .slogan-words {
    opacity: 1 !important;
  }
}
.toggle-logo-enter-active {
  position: absolute;
  width: 30vw;
  top: 93%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: top .5s ease-in;
  .slogan-words {
    opacity: 0 !important;
    transition: opacity .5s ease-in;
  }
}
.toggle-logo-enter-done {
  position: absolute;
  width: 30vw;
  top: 93%;
  left: 50%;
  transform: translate(-50%, -50%);
  .slogan-words {
    opacity: 0 !important;
  }
}
.toggle-logo-exit {
  position: absolute;
  width: 30vw;
  top: 93%;
  left: 50%;
  transform: translate(-50%, -50%);
  .slogan-words {
    opacity: 0 !important;
  }
}
.toggle-logo-exit-active {
  position: absolute;
  width: 80vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: top .5s ease-in;
  .slogan-words {
    opacity: 1 !important;
    transition: opacity .5s ease-in;
  }
}
.toggle-logo-exit-done {
  position: absolute;
  width: 80vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .slogan-words {
    opacity: 1 !important;
  }
}

.about {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  h1 {
    font-size: 5vw;
  }
  h3 {
    font-weight: 300;
    font-size: 3vw;
    text-align: left;
  }
}

@media only screen and (min-width: 600px) {
  .about h1 {
    font-size: 35px;
  }
  .about h3 {
    margin-top: 25px;
    font-size: 25px;
  }
}

.contact {
  animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.skills {
  animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.profileImg {
  height: 20vh;
  margin: auto;
  border-radius: 50%;
  border: .2rem solid white;
  padding: .3rem;
}
